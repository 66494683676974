import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Cookies from "js-cookie";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import { removeFromWishlist, setWishlistQuantity } from "../../app/actions";
import styles from "./styles";
import qbContact from "../../images/qb-contact.jpg";
import logo from "../../images/td-logo-white.png";
import qbFaq from "../../images/qb-faq.jpg";

import Breadcrumb from "../Breadcrumb";
import { CgbImageLinkBlock } from "../blocks/CgbImageLinkBlock";
import Form from "./Form";

import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock";
import { CoreButtonBlock } from "../blocks/CoreButtonBlock";
import { isBrowser } from "../../helpers";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromWishlist: (id) => dispatch(removeFromWishlist(id)),
  setWishlistQuantity: (data) => dispatch(setWishlistQuantity(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Pdf = ({ products }) => {
  if (!isBrowser) return null;

  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            backgroundColor: "#19365B",
            padding: "12px",
            textAlign: "center",
            marginBottom: "24px",
          }}
        >
          <Image
            src={logo}
            style={{
              width: "120px",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </View>

        <View
          style={{
            margin: "10px",
            padding: "10px",
          }}
        >
          {products.map((product, index) => {
            let image = logo;
            if (
              product.data.product.productGallery &&
              product.data.product.productGallery[0].localFile.publicURL
            ) {
              image =
                product.data.product.productGallery[0].localFile.publicURL;
            }

            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "24px 0",
                  borderBottom: "1px solid #E7EAEE",
                }}
              >
                <Image
                  src={image}
                  style={{
                    width: "75px",
                    height: "auto",
                    marginRight: "12px",
                  }}
                />
                <View
                  style={{
                    flexGrow: 1,
                  }}>
                  {product.data.brands !== null && (
                    <Image
                      src={
                        product.data.brands.nodes[0].BrandExtras.logo.localFile
                          .publicURL
                      }
                      style={{
                        width: "60px",
                        height: "auto",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                  <Text
                    style={{
                      fontWeight: "bold",
                      lineHeight: "2",
                      fontSize: "12px",
                      color: "#19365B",
                    }}
                  >
                    {product.data.title}
                  </Text>
                  <Text
                    style={{
                      color: "#19365B",
                      opacity: ".3",
                      fontSize: "8px",
                      lineHeight: "1.5",
                      marginBottom: "10px",
                    }}
                  >
                    {product.code}
                  </Text>
                  <Text
                    style={{
                      opacity: ".75",
                      fontSize: "8px",
                      lineHeight: "1.5",
                    }}
                  >
                    {product.data.product.shortDescription}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      opacity: ".75",
                      fontSize: "8px",
                      lineHeight: "2",
                      marginTop: "25px",
                      width: "75px",
                      textAlign: "center",
                    }}>
                   Quantity
                  </Text>
                  <Text
                    style={{
                      opacity: ".75",
                      fontSize: "8px",
                      lineHeight: "2",
                      width: "75px",
                      textAlign: "center",
                    }}>
                    {product.quantity}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

function QuoteBuilder({ products, wishlist, removeFromWishlist, setWishlistQuantity }) {
  const classes = useStyles();

  React.useEffect(() => {
    Cookies.set("wishlist", wishlist);
  }, [wishlist]);

  const p = products.edges.map((product) => product.node);

  const filteredProducts = wishlist.map((item) => {
    let result = {};
    p.forEach((pr) => {
      if (!pr.product) return;
      if (pr.product.code === Number(item.c)) {
        result = {
          data: pr,
          code: item.c,
          quantity: item.q
        };
      }

      if (pr.product.variations) {
        pr.product.variations.forEach((variation) => {
          if (variation.variantCode === item.c) {
            result = {
              data: pr,
              code: item.c,
              quantity: item.q
            };
          }
        });
      }
    });

    return result;
  });

  return (
    <>
      <Breadcrumb
        type="category"
        current={{
          title: "Quote Builder",
          slug: "quote-builder",
          uri: "/quote-builder",
        }}
      />
      <section className={classes.productListing}>
        <div className="listing">
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: classes.header,
              content: "Quote Builder",
              level: 3,
              textColor: "",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <div className="products">
            {filteredProducts.map((product, index) => {
              let image = logo;
              if (
                product.data.product.productGallery &&
                product.data.product.productGallery[0].sourceUrl
              ) {
                image = product.data.product.productGallery[0].sourceUrl;
              }
              return (
                <div className={classes.product} key={index}>
                  <button onClick={() => removeFromWishlist(product.code)}>
                    x
                  </button>
                  <Link
                    to={product.data.uri}
                    style={{ textDecoration: "none" }}
                  >
                    {product.data && (
                      <img
                        src={image}
                        alt={product.data.title}
                        className="placeholder"
                      />
                    )}
                  </Link>
                  <Link
                    to={product.data.uri}
                    style={{ textDecoration: "none" }}
                    className="product-info-link"
                  >
                    <span className="product-info">
                      <span className="title">{product.data.title}</span>
                      <span className="code">{product.code}</span>
                    </span>
                  </Link>
                  <span className="product-quantity">
                    <input type="number" value={product.quantity} onChange={e => setWishlistQuantity({ code: product.code, quantity: e.target.value })} />
                  </span>
                </div>
              );
            })}
          </div>
          <div className="actions">
            {isBrowser && (
              <PDFDownloadLink
                document={<Pdf products={filteredProducts} />}
                fileName="quotes.pdf"
                className="download"
              >
                {({ blob, url, loading, error }) => {
                  if (loading) {
                    return "Generating...";
                  }
                  return "Download PDF";
                }}
              </PDFDownloadLink>
            )}
            {/* <CoreButtonBlock
              attributes={{
                align: "",
                backgroundColor: "tertiary",
                borderRadius: 0,
                className: classes.button,
                gradient: "",
                linkTarget: "",
                placeholder: "",
                rel: "",
                text: "Find a Stockist",
                textColor: "secondary",
                title: "",
                url: "/stockist-search/",
                __typename: "WpCoreButtonBlockAttributes",
              }}
            /> */}
          </div>
        </div>
        <Form products={filteredProducts} />
      </section>
      {/* <section>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="left"
          alignItems="center"
          className={`image-link-row ${classes.imageLinkRow} ${classes.imageLinkRowTheme}`}
        >
          <Grid item xs={12} md={4}>
            <CgbImageLinkBlock
              attributes={{
                text: "Contact the team",
                linkURL: "/about-us/contact-us/",
                mediaUrl: qbContact,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CgbImageLinkBlock attributes={{ text: 'Frequently asked questions', linkURL: '/expertise/faqs', mediaUrl: qbFaq }} />
          </Grid>
        </Grid>
      </section> */}
    </>
  );
}

export default connector(QuoteBuilder);
