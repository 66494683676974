export default theme => ({
    form: {
        '& fieldset': {
            display: 'flex',
            flexDirection: 'column'
        },
    },
    success: {
        fontSize: 16,
        color: theme.palette.common.white
    }
});