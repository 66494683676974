import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import QuoteBuilder from "../components/QuoteBuilder";

class Page extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout
        meta={{
          metaDescription:
            "Create custom quotes effortlessly with Cronex’s intuitive Quote Builder.",
          metaTitle: "Quote Builder | Cronex ",
          ogDescription: "",
          ogTitle: "",
          twitterCardType: "",
          twitterDescription: "",
          twitterTitle: "",
          canonical: "/quote-builder/",
        }}
        path={"a"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <QuoteBuilder products={this.props.data.allWpProduct} />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allWpProduct {
      edges {
        node {
          id
          title
          uri
          product {
            code
            shortDescription
            variations {
              variantCode
            }
            productGallery {
              id
              sourceUrl
              localFile {
                id
                publicURL
              }
            }
          }
          brands {
            nodes {
              name
              BrandExtras {
                logo {
                  id
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page;
